import { takeEvery, call, put } from 'redux-saga/effects';
import { bcryptPassword } from '../../utils/bcrypt';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import * as constants from './constants';

function* cancelableCreatePrestoCluster(action) {
  const { values } = action.payload;

  const body = {
    name: values.name,
    clusterConfiguration: {
      workerNodes: parseInt(values.workerNodes, 10),
      instanceType: values.instanceType,
      prestoCoordinatorInstanceType: values.prestoCoordinatorInstanceType,
      enableAhanaManagedHMS: values.ahanaManagedHms,
      concurrency: values.concurrency,
      enableFragmentResultCache: values.enableFragmentResultCache,
      enableSpot: values.enableSpot,
      workerContainerType: values.prestoCPP ? 'prestoCPP' : 'java',
      prestoVersion: values.prestoVersion,
    },
  };

  if (values.prestoUsers) {
    body.prestoUserIds = Object.entries(values.prestoUsers).reduce(
      (acc, [key, value]) => {
        if (value) {
          acc.push(key);
        }
        return acc;
      },
      [],
    );
  } else {
    const passwordHash = yield call(
      bcryptPassword,
      values.credentials.password,
    );
    body.credentials = [
      {
        type: values.credentials.type,
        username: values.credentials.username,
        password: passwordHash,
      },
    ];
  }

  if (values.availabilityZones && values.availabilityZones !== 'Automatic') {
    body.clusterConfiguration.availabilityZones = [values.availabilityZones];
  }

  if (values.privateSubnetIds && values.privateSubnetIds !== 'Automatic') {
    body.clusterConfiguration.privateSubnetIds = [values.privateSubnetIds];
  }

  if (values.ahanaManagedHms) {
    body.clusterConfiguration.hmsInstanceType = values.hmsInstanceType;
    body.clusterConfiguration.attachQueryLogTable = values.attachQueryLogTable;
  }

  if (values.enableCache) {
    body.clusterConfiguration.enableCache = values.enableCache;
  }

  if (values.enableDataCache) {
    body.clusterConfiguration.enableDataCache = 'alluxio-lite';
  }

  if (values.autoScaling) {
    if (values.autoScaling.type === constants.autoScaling.idleCostSavings) {
      if (values.autoScaling.enableIdleCostSavings) {
        body.autoScaling = {
          type: values.autoScaling.type,
          workerNodes: parseInt(values.autoScaling.workerNodes, 10),
          idleTime: parseInt(values.autoScaling.idleTime, 10),
        };
      }
      body.clusterConfiguration.workerNodes = parseInt(
        values.autoScaling.workerNodes,
        10,
      );
    } else if (values.autoScaling.type === 'dynamic') {
      body.autoScaling = {
        type: values.autoScaling.dynamic.type,
        minWorkerNodes: parseInt(values.autoScaling.minWorkerNodes, 10),
        maxWorkerNodes: parseInt(values.autoScaling.maxWorkerNodes, 10),
        stepSize: parseInt(values.autoScaling.stepSize, 10),
        idleTime: parseInt(values.autoScaling.idleTime, 10),
        target: parseInt(values.autoScaling.target, 10),
      };
      body.clusterConfiguration.workerNodes = parseInt(
        body.autoScaling.minWorkerNodes,
        10,
      );
    } else {
      throw new Error(`Unknown auto scaling type ${values.autoScaling.type}`);
    }
  }

  if (values.prestoWorkerTerminationGracePeriod) {
    body.clusterConfiguration.prestoWorkerTerminationGracePeriod =
      values.prestoWorkerTerminationGracePeriod * 60;
  }

  if (values.customTagKeys?.length > 0 && values.customTagValues?.length > 0) {
    body.clusterConfiguration.customTags = {};
    values.customTagKeys.forEach((key, i) => {
      if (key) {
        body.clusterConfiguration.customTags[`${key}`] =
          values.customTagValues[i];
      }
    });
  }
  yield put(actions.post(body));
}

export default function* prestoClusterAddRootSaga() {
  yield takeEvery(actionTypes.submit, cancelableCreatePrestoCluster);
}
