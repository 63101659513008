import Help from '../../Help';

const AWSInstanceType = (isCommunityEditionTenant) => {
  return () => {
    return (
      <Help.HelpSection>
        <Help.HelpHeader>Worker Node Instance Type</Help.HelpHeader>
        <Help.P>
          A Presto worker is responsible for executing tasks and processing
          data. Worker nodes fetch data from connectors and exchange
          intermediate data with each other. The coordinator is responsible for
          fetching results from the workers and returning the final results to
          the client.
        </Help.P>
        <Help.P>
          Select the{' '}
          <a
            href='https://aws.amazon.com/ec2/instance-types/'
            target='_blank'
            rel='noopener noreferrer'
          >
            AWS EC2 instance type
          </a>{' '}
          to be provisioned for the Presto cluster worker nodes. The recommended
          instance type is r5.2xlarge with 8 vCPUs and 64 GB memory.
        </Help.P>
        <Help.P>
          If you have selected a &quot;d&quot; type EC2 instance and then both
          data IO and intermediate result set cache will be enabled using the
          instance storage by default instead of EBS volumes.
        </Help.P>
        {isCommunityEditionTenant && (
          <Help.P>
            The available instance types are limited for community edition
            users.
          </Help.P>
        )}
      </Help.HelpSection>
    );
  };
};

const PrestoCoordinatorAWSInstanceType = (isCommunityEditionTenant) => {
  return () => {
    return (
      <Help.HelpSection>
        <Help.HelpHeader>Coordinator AWS Instance Type</Help.HelpHeader>
        <Help.P>
          The Presto coordinator is responsible for parsing statements, planning
          queries and scheduling queries. Clients like JDBC / ODBC / PrestoCli
          connect to the coordinator to submit statements for execution. Every
          Presto installation must have a Presto coordinator alongside one or
          more Presto workers.
        </Help.P>
        <Help.P>
          Select the{' '}
          <a
            href='https://aws.amazon.com/ec2/instance-types/'
            target='_blank'
            rel='noopener noreferrer'
          >
            AWS EC2 instance type
          </a>{' '}
          to be provisioned for the Presto Coordinator. The recommended instance
          type is r5.4xlarge with 16 vCPUs and 128 GB memory.
        </Help.P>
        {isCommunityEditionTenant && (
          <Help.P>
            The available instance types are limited for community edition
            users.
          </Help.P>
        )}
      </Help.HelpSection>
    );
  };
};

const EnableSpot = (isCommunityEditionTenant) => {
  return () => {
    return (
      <Help.HelpSection>
        <Help.HelpHeader>Enable Spot</Help.HelpHeader>
        {isCommunityEditionTenant && (
          <Help.P>
            This feature is not available for community edition users.
          </Help.P>
        )}
      </Help.HelpSection>
    );
  };
};

const AWSAvailabilityZone = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>AWS Availability Zone</Help.HelpHeader>
      <Help.P>
        Select the AWS availability zone where you would like to deploy your
        presto cluster.
      </Help.P>
      <Help.P>
        If you select &quot;Automatic&quot; we will try to determine the most
        optimal availability zone when it is deployed.
      </Help.P>
    </Help.HelpSection>
  );
};

const AWSPrivateSubnets = () => {
  return (
    <Help.HelpSection>
      <Help.HelpHeader>AWS Private Subnets</Help.HelpHeader>
      <Help.P>
        Select the Private Subnets where you would like to deploy your presto
        cluster.
      </Help.P>
    </Help.HelpSection>
  );
};

const module = {
  AWSInstanceType,
  PrestoCoordinatorAWSInstanceType,
  EnableSpot,
  AWSAvailabilityZone,
  AWSPrivateSubnets,
};

export default module;
