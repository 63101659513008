/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect } from 'react';
import { arrayOf, func, bool, string, shape, number } from 'prop-types';
import PrestoClusters from '../../PrestoClusters';
import Form from '../../Form';
import Page from '../../Page';
import Help from '../../Help';
import PrestoUsersAdd from '../../PrestoUsersAdd';
import PrestoUsers from '../../PrestoUsers';
import IdentityProviders from '../../IdentityProviders';
import IdentityProviderAttached from './IdentityProviderAttached';
import { DropDownValue } from '../propTypes';
import PrestoClusterDetails from './PrestoClusterDetails';
import ClusterSettings from './ClusterSettings';
import MultiUserClusterAuth from './MultiUserClusterAuth';
import PageHelp from './PageHelp';
import DataLakeSettings from './DataLakeSettings';
import CustomTags from '../../CustomTags/CustomTags';

const display = {
  header: 'Create a Cluster',
  addButton: 'Create Cluster',
  cancelButton: 'Cancel',
  confirmCancel:
    'Proceeding with the action will result in the loss of all changes. Would you like to proceed?',
  savingPrestoCluster: 'Creating cluster...',
};

const AddPrestoClusterForm = ({
  errors,
  submitInProgress,
  submitSuccess,
  usePop,
  prestoUsers,
  identityProvider,
  identityProviderLoading,
  enableIdentityProviderFeature,
  loadIdentityProviders,
  enableEnableSpot,
  maxWorkerNodeCount,
  instanceTypeValues,
  coordinatorInstanceTypeValues,
  hmsInstanceTypeValues,
  awsAZSelect,
  prestoCPPEnabled,
  prestoVersions,
  prestoVersionsLoading,
  awsPrivateSubnetSelect,
  awsSubnets,
}) => {
  const { onCancel } = Form.useFormNavigation({
    submitSuccess,
    confirmCancelMessage: display.confirmCancel,
    getRoute: PrestoClusters.routes.getRoute,
    usePop,
  });

  return (
    <Help.HelpNavigation>
      <Page.PageHeader>
        <h1>
          {display.header} {usePop}
        </h1>
        <div className='buttons'>
          <Form.SecondaryButton
            onClick={onCancel}
            label={display.cancelButton}
            disabled={submitInProgress}
            disabledOnErrors={false}
          />
        </div>
      </Page.PageHeader>
      <Page.FormPage>
        <Page.FormColumn>
          <PrestoClusterDetails
            disabled={submitInProgress}
            error={errors.details}
            errorVersions={errors.prestoVersions}
            prestoVersions={prestoVersions}
            prestoVersionsLoading={prestoVersionsLoading}
          />
          <ClusterSettings
            enableEnableSpot={enableEnableSpot}
            disabled={submitInProgress}
            error={errors.clusterSettings}
            maxWorkerNodeCount={maxWorkerNodeCount}
            instanceTypeValues={instanceTypeValues}
            coordinatorInstanceTypeValues={coordinatorInstanceTypeValues}
            awsAZSelect={awsAZSelect}
            awsPrivateSubnetSelect={awsPrivateSubnetSelect}
            awsSubnets={awsSubnets}
          />
          <DataLakeSettings
            hmsInstanceTypeValues={hmsInstanceTypeValues}
            disabled={submitInProgress}
            error={errors.dataLakeSettings}
            prestoCPPEnabled={prestoCPPEnabled}
          />
          {enableIdentityProviderFeature && (
            <IdentityProviderAttached
              loadingInProgress={identityProviderLoading}
              identityProvider={identityProvider}
              loadIdentityProviders={loadIdentityProviders}
            />
          )}
          <MultiUserClusterAuth
            disabled={submitInProgress}
            error={errors.clusterAuth}
            fieldPrefix='credentials'
            prestoUsers={prestoUsers}
          />
          <CustomTags disabled={submitInProgress} error={errors.customTags} />
          <Page.Buttons>
            <Form.SecondaryButton
              onClick={onCancel}
              label={display.cancelButton}
              disabled={submitInProgress}
              disabledOnErrors={false}
            />
            <Form.Submit
              label={
                !submitInProgress
                  ? display.addButton
                  : display.savingPrestoCluster
              }
              disabled={submitInProgress}
              showLoading={submitInProgress}
            />
          </Page.Buttons>
        </Page.FormColumn>
        <Help.HelpColumn DefaultHelp={PageHelp} />
      </Page.FormPage>
    </Help.HelpNavigation>
  );
};

AddPrestoClusterForm.defaultProps = {
  submitInProgress: false,
  submitSuccess: false,
  usePop: true,
  errors: {},
  prestoUsers: undefined,
  identityProvider: null,
  identityProviderLoading: false,
  enableIdentityProviderFeature: false,
  enableEnableSpot: false,
  awsAZSelect: null,
  prestoVersions: null,
  prestoVersionsLoading: true,
  awsPrivateSubnetSelect: null,
  awsSubnets: null,
};

AddPrestoClusterForm.propTypes = {
  submitInProgress: bool,
  submitSuccess: bool,
  errors: shape({
    details: string,
    clusterSettings: string,
    awsPermissions: string,
    customTags: string,
  }),
  usePop: bool,
  prestoUsers: arrayOf(PrestoUsers.propTypes.PrestoUser),
  identityProvider: IdentityProviders.propTypes.IdentityProvider,
  identityProviderLoading: bool,
  loadIdentityProviders: func.isRequired,
  enableIdentityProviderFeature: bool,
  enableEnableSpot: bool,
  maxWorkerNodeCount: number.isRequired,
  instanceTypeValues: arrayOf(DropDownValue).isRequired,
  coordinatorInstanceTypeValues: arrayOf(DropDownValue).isRequired,
  hmsInstanceTypeValues: arrayOf(DropDownValue).isRequired,
  awsAZSelect: arrayOf(string),
  awsPrivateSubnetSelect: arrayOf(string),
  awsSubnets: arrayOf(
    shape({
      id: string,
      name: string,
    }),
  ),
  prestoCPPEnabled: bool.isRequired,
  prestoVersions: arrayOf(string),
  prestoVersionsLoading: bool,
};

const AddPrestoCluster = ({
  create,
  errors,
  submitInProgress,
  submitSuccess,
  usePop,
  prestoUsers,
  loadPrestoUsers,
  resetCreatePrestoUser,
  identityProvider,
  identityProviderLoading,
  enableIdentityProviderFeature,
  loadIdentityProviders,
  enableEnableSpot,
  maxWorkerNodeCount,
  instanceTypeValues,
  coordinatorInstanceTypeValues,
  awsAZSelect,
  hmsInstanceTypeValues,
  prestoCPPEnabled,
  computePlaneId,
  loadPrestoVersions,
  prestoVersions,
  prestoVersionsLoading,
  awsPrivateSubnetSelect,
  awsSubnets,
  getVPCSubnets,
}) => {
  useEffect(() => {
    loadPrestoUsers();
    if (computePlaneId) {
      loadPrestoVersions(computePlaneId);
      getVPCSubnets(computePlaneId);
    }
  }, [loadPrestoUsers, computePlaneId, loadPrestoVersions, getVPCSubnets]);

  const showCreatePrestoUser =
    PrestoUsersAdd.components.useCreatePrestoUserState();
  useEffect(() => {
    if (!showCreatePrestoUser) {
      resetCreatePrestoUser();
    }
  }, [showCreatePrestoUser, resetCreatePrestoUser]);
  const dispatch = PrestoUsersAdd.components.useCreatePrestoUserDispatch();
  const hideCreatePrestoUser = useCallback(() => {
    return dispatch(false);
  }, [dispatch]);

  return (
    <>
      {showCreatePrestoUser && (
        <PrestoUsersAdd.Container hideCreatePrestoUser={hideCreatePrestoUser} />
      )}
      <Page.PageWithOverflow
        style={{ display: showCreatePrestoUser ? 'none' : undefined }}
      >
        <Form.Form name='add-presto-cluster' submitAction={create}>
          <AddPrestoClusterForm
            submitInProgress={submitInProgress}
            submitSuccess={submitSuccess}
            errors={errors}
            usePop={usePop}
            prestoUsers={prestoUsers}
            identityProviderLoading={identityProviderLoading}
            identityProvider={identityProvider}
            enableIdentityProviderFeature={enableIdentityProviderFeature}
            loadIdentityProviders={loadIdentityProviders}
            enableEnableSpot={enableEnableSpot}
            maxWorkerNodeCount={maxWorkerNodeCount}
            instanceTypeValues={instanceTypeValues}
            coordinatorInstanceTypeValues={coordinatorInstanceTypeValues}
            awsAZSelect={awsAZSelect}
            hmsInstanceTypeValues={hmsInstanceTypeValues}
            prestoCPPEnabled={prestoCPPEnabled}
            prestoVersions={prestoVersions}
            prestoVersionsLoading={prestoVersionsLoading}
            awsPrivateSubnetSelect={awsPrivateSubnetSelect}
            awsSubnets={awsSubnets}
          />
        </Form.Form>
      </Page.PageWithOverflow>
    </>
  );
};

AddPrestoCluster.defaultProps = {
  submitInProgress: false,
  submitSuccess: false,
  errors: {},
  usePop: true,
  prestoUsers: undefined,
  identityProvider: null,
  identityProviderLoading: false,
  enableIdentityProviderFeature: false,
  enableEnableSpot: false,
  awsAZSelect: null,
  computePlaneId: null,
  prestoVersions: null,
  prestoVersionsLoading: true,
  awsPrivateSubnetSelect: null,
  awsSubnets: null,
};

AddPrestoCluster.propTypes = {
  create: func.isRequired,
  submitInProgress: bool,
  submitSuccess: bool,
  errors: shape({
    details: string,
    clusterSettings: string,
    awsPermissions: string,
  }),
  usePop: bool,
  prestoUsers: arrayOf(PrestoUsers.propTypes.PrestoUser),
  loadPrestoUsers: func.isRequired,
  resetCreatePrestoUser: func.isRequired,
  identityProvider: IdentityProviders.propTypes.IdentityProvider,
  identityProviderLoading: bool,
  loadIdentityProviders: func.isRequired,
  enableIdentityProviderFeature: bool,
  enableEnableSpot: bool,
  maxWorkerNodeCount: number.isRequired,
  instanceTypeValues: arrayOf(DropDownValue).isRequired,
  coordinatorInstanceTypeValues: arrayOf(DropDownValue).isRequired,
  hmsInstanceTypeValues: arrayOf(DropDownValue).isRequired,
  awsAZSelect: arrayOf(string),
  prestoCPPEnabled: bool.isRequired,
  loadPrestoVersions: func.isRequired,
  computePlaneId: string,
  prestoVersions: arrayOf(string),
  prestoVersionsLoading: bool,
  awsPrivateSubnetSelect: arrayOf(string),
  awsSubnets: arrayOf(
    shape({
      id: string,
      name: string,
    }),
  ),
  getVPCSubnets: func.isRequired,
};

const { CreatePrestoUserNavigation } = PrestoUsersAdd.components;
const AddPrestoClusterWrapper = (props) => {
  return (
    <CreatePrestoUserNavigation>
      <AddPrestoCluster {...props} />
    </CreatePrestoUserNavigation>
  );
};

AddPrestoClusterWrapper.defaultProps = {
  submitInProgress: false,
  submitSuccess: false,
  errors: {},
  usePop: true,
  prestoUsers: undefined,
  prestoUsersLoading: false,
};

AddPrestoClusterWrapper.propTypes = {
  create: func.isRequired,
  submitInProgress: bool,
  submitSuccess: bool,
  errors: shape({
    details: string,
    clusterSettings: string,
    awsPermissions: string,
  }),
  usePop: bool,
  prestoUsers: arrayOf(PrestoUsers.propTypes.PrestoUser),
  prestoUsersLoading: bool,
  loadPrestoUsers: func.isRequired,
  resetCreatePrestoUser: func.isRequired,
  maxWorkerNodeCount: number.isRequired,
  instanceTypeValues: arrayOf(DropDownValue).isRequired,
  coordinatorInstanceTypeValues: arrayOf(DropDownValue).isRequired,
  hmsInstanceTypeValues: arrayOf(DropDownValue).isRequired,
  prestoCPPEnabled: bool.isRequired,
};

export default AddPrestoClusterWrapper;
